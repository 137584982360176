import { Link } from "react-router-dom";
import { useRef } from "react";
import { useResolvedPath, useMatch } from "react-router-dom";
import useWindowDimensions from "./widthFinder";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

export default function Navbar() {
  const { height, width } = useWindowDimensions();
  if (width > 600) {
    return (
      <nav className="nav">
        <Link to="/" className="site-title">
          Alpha Network
        </Link>
        <ul>
          <li>
            <CustomLink to="/realms">Realms</CustomLink>
          </li>
          <li>
            <CustomLink to="/about">About</CustomLink>
          </li>
          <li>
            <CustomLink to="https://alpha-network-store.tebex.io/">Store</CustomLink>
          </li>
        </ul>
      </nav>
    );
  } else {
    return (
      <div className="header">
        <div className="nav">
          <Link to="/" className="site-title">
            ALPHA NETWORK
          </Link>

          <div class="dropdown">
            <button class="dropbtn">Menu</button>
            <div class="dropdown-content">
              <Link to="/">Home</Link>
              <Link to="/realms">Realms</Link>
              <Link to="/about">About</Link>
              <Link to="https://alpha-network-store.tebex.io/">Store</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function CustomLink({ to, children, ...props }) {
  const reslovedPath = useResolvedPath(to);
  const isActive = useMatch({ path: reslovedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : "off"}>
      <Link to={to}>{children}</Link>
    </li>
  );
}
