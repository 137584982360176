import skyblock from "../assets/skyblock.png";
import kit from "../assets/kit.png";
import { Link } from "react-router-dom";
import Footer from "../components/Footer.jsx";

export default function Realms() {
  return (
    <>
      <div className="realms">
        <h1>Our Realms</h1>
        <img src={skyblock} alt="render of minecraft skyblock realm"></img>
        <div className="realm-container">
          <h1>Alpha Skyblock</h1>
          <p>
            Code: <Link to="https://realms.gg/-2RhdrHyz-w">-2RhdrHyz-w</Link>
          </p>
          <p>
            About: 🌎︱A popular skyblock, Many unique and well made features.<br></br> 🎈︱Frequent giveaways and enticing events.<br></br> 🤝︱Friendly and welcoming staff.<br></br> 💬︱A safe environment for work, socializing and more.<br></br> ⬆️︱Popular and ever growing realm and discord server.<br></br>
          </p>
        </div>
        <hr></hr>
        <img src={kit} alt="render of minecraft kitpvp realm"></img>
        <div className="realm-container2">
          <h1>Alpha KitPvP</h1>
          Code: <Link to="https://realms.gg/gzaPFjtkAWc">gzaPFjtkAWc</Link>
          <p>
            With Kit PVP, you may play one of the most popular Minecraft gamemodes online for free. In addition, the realm is ideal for PvP battles (PVP). Your unique items will be added to a pre-set category of the Kit. Prepare to battle your friends and other players in Minecraft to see who will win.<br></br>
            <br></br> Each person who enters a Minecraft world has one goal: to get points. The Best Minecraft KitPvP Realms List was created to let players choose their favorite from the list. Play against other players to get reputation and points.<br></br>
            <br></br>The Kit PVP game may be easy if you utilize the best worlds accessible. Use the best strategies and methods to win. To increase your chances of earning more points faster, you must get acquainted with the Minecraft KitPvP world that you want to use.
          </p>
        </div>
      </div>
      <div className="mobile">
        <h1>Our Realms</h1>
        <img id="realm-m" src={skyblock} alt="render of minecraft skyblock realm"></img>
        <div className="realm-container-mobile">
          <h1>Alpha Skyblock</h1>
          <p>
            Code: <Link to="https://realms.gg/-2RhdrHyz-w">-2RhdrHyz-w</Link>
          </p>
          <p>
            About: 🌎︱A popular skyblock, Many unique and well made features.<br></br> 🎈︱Frequent giveaways and enticing events.<br></br> 🤝︱Friendly and welcoming staff.<br></br> 💬︱A safe environment for work, socializing and more.<br></br> ⬆️︱Popular and ever growing realm and discord server.<br></br>
          </p>
        </div>
        <hr></hr>
        <br></br>
        <img id="realm-m" src={kit} alt="render of minecraft kitpvp realm"></img>
        <div className="realm-container-mobile2">
          <h1>Alpha KitPvP</h1>
          Code: <Link to="https://realms.gg/gzaPFjtkAWc">gzaPFjtkAWc</Link>
          <p>
            With Kit PVP, you may play one of the most popular Minecraft gamemodes online for free. In addition, the realm is ideal for PvP battles (PVP). Your unique items will be added to a pre-set category of the Kit. Prepare to battle your friends and other players in Minecraft to see who will win.<br></br>
            <br></br> Each person who enters a Minecraft world has one goal: to get points. The Best Minecraft KitPvP Realms List was created to let players choose their favorite from the list. Play against other players to get reputation and points.<br></br>
            <br></br>The Kit PVP game may be easy if you utilize the best worlds accessible. Use the best strategies and methods to win. To increase your chances of earning more points faster, you must get acquainted with the Minecraft KitPvP world that you want to use.
          </p>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
